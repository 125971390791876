.blocks-group-wrapper.blue {
  background-color: var(--alternate-color);

  p {
    color: var(--reverse-color);

    > a {
      color: var(--reverse-color) !important;
    }
  }
}

.has--backgroundColor--blue {
  background: var(--alternate-color);

  p {
    color: var(--reverse-color);

    > a {
      color: var(--reverse-color) !important;
    }
  }
}

body.acc-cont-h .blocks-group-wrapper.blue,
body.acc-cont-h .blocks-group-wrapper.grey,
body.acc-cont-h .block.has--backgroundColor--blue,
body.acc-cont-h .block.has--backgroundColor--grey {
  background: var(--background-color) !important;

  p {
    color: var(--text-color) !important;

    > a {
      color: var(--text-color) !important;
    }
  }
}

#page-add .block-editor-accordion.has--backgroundColor--blue,
#page-edit .block-editor-accordion.has--backgroundColor--blue {
  .accordion.ui.fluid.styled {
    background: var(--alternate-color);
  }
}
