.ui.secondary.pointing.menu .item {
  background-color: transparent;
  box-shadow: none;
  color: #000;
  @include add(weight, regular);

  &.active {
    border-color: var(--main-color-dark);

    &:hover {
      border-color: var(--main-color);
    }
  }

  &:hover {
    border-color: var(--main-color);
  }
}
