body.acc-cont-h .rdt_Table {
  background-color: var(--background-color) !important;

  .rdt_TableHead {
    .rdt_TableHeadRow {
      background-color: var(--main-color);

      .rdt_TableCol {
        color: var(--reverse-color);
        text-wrap: wrap;
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      border-bottom-color: var(--text-color) !important;
      background-color: var(--background-color) !important;
      color: var(--text-color);

      svg {
        fill: var(--text-color) !important;
      }
    }
  }
}

.rdt_Table {
  background-color: var(--background-color) !important;

  .rdt_TableHead {
    .rdt_TableHeadRow {
      padding: $spacing-small 0px;
      background-color: var(--main-color);

      .rdt_TableCol {
        color: var(--reverse-color);
        text-wrap: wrap;
      }
    }

    .rdt_TableRow {
      background-color: var(--background-color) !important;
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      padding: $spacing-small 0px !important;

      .rdt_TableCell {
        .wrap {
          text-wrap: wrap;
        }

        .link {
          > a {
            font-size: var(--font-size-xs);
            font-weight: var(--font-weight-light);
            line-height: var(--line-height-s);

            &:hover {
              font-weight: var(--font-weight-bold);
            }
          }
        }

        .list {
          > .main {
            &::after {
              content: ', ';
            }

            &:last-child {
              &::after {
                content: '';
              }
            }
          }
        }
      }
    }
  }
}

.results-table {
  padding: 10px;
  border: 1px solid var(--main-color);

  .data-metadata {
    margin-bottom: 1rem;

    .metadata-title {
      @include add(weight, bold);
    }

    .metadata-timeframe {
      @include add(weight, medium);
    }

    .metadata-label {
      @include add(weight, medium);
    }

    .metadata-value {
      @include add(weight, light);
    }

    .actions.downloads {
      a.external::after {
        content: '';
      }
    }
  }

  .data-table {
    .headers {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      border-bottom: solid 2px var(--ds-border, #dfe1e6);
      background-color: var(--main-color);
    }

    .column-header {
      padding: 10px 12px;
      background-color: var(--main-color);
      color: var(--reverse-color);
      text-align: left;
    }

    .data-item {
      &.noChildren {
        padding: 10px 12px;
      }

      &.no-result {
        text-align: center;
      }

      > span {
        width: 100%;
        font-family: 'sans-serif';
        @include add(size, xs);
      }

      &.percentage,
      &.currency {
        text-align: right;
      }
    }
  }
}
