.vereador-view {
  #card {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    justify-items: center;
    @media only screen and (max-width: $largest-mobile-screen) {
      display: block;
      text-align: center;
    }

    .image {
      display: flex;
      width: 200px !important;
      align-content: center;
      @media only screen and (max-width: $largest-mobile-screen) {
        display: block;
        width: 100% !important;
      }

      img.portrait {
        width: 200px !important;
        height: 200px !important;
        margin-right: 40px;
        object-fit: cover;
      }
    }

    .data {
      width: 100% !important;

      > .q.container.details > .ui.table {
        width: 100% !important;
      }
      @media only screen and (max-width: $largest-mobile-screen) {
        width: 100% !important;

        > .q.container.details > .ui.table {
          margin-right: $spacing-small;
          margin-left: $spacing-small;
        }
      }

      .info {
        padding-left: 0px;
        margin-top: 30px;
        margin-bottom: 30px;

        li {
          line-height: 2rem;
          list-style-type: none;
        }
      }

      .label {
        @include add(weight, semi-bold);
      }
    }
  }
}
