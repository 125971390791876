#page-document .blocks-group-wrapper > .block.search .searchBlock-container {
  @include layout-container-width();

  h1.documentFirstHeading {
    @include default-container-width();
  }

  .row.facets {
    @include default-container-width();
  }

  .row.results {
    @include layout-container-width();
  }
}
