@mixin block_grid_h2() {
  @include add(size, m);
  @include add(height, m);
  @include add(weight, bold);
  text-align: center;
}

@mixin block_grid_p() {
  @include add(size, xs);
  @include add(height, s);
  @include add(weight, regular);
  padding: $spacing-small 0px;
  text-align: center;
}
