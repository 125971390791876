.ui.table.details {
  td.label {
    background-color: var(--reverse-color-light);
    @include add(weight, semi-bold);

    &.center {
      text-align: center;
    }
  }

  td.value {
    &.center {
      text-align: center;
    }
  }
}
