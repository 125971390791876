body.contenttype-contratacao {
  .contratacao-view.view-wrapper {
    max-width: var(--default-container-width) !important;
    margin-right: auto;
    margin-left: auto;

    .header {
      display: flex;
      align-items: center;

      .documentFirstHeading {
        align-self: center;
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }

    .colaborador {
      margin-right: $spacing-small;
    }

    .text {
      &.wrap {
        text-wrap: wrap;
      }
    }
  }
}

#page-add,
#page-edit {
  .field.textarea {
    .wrapper {
      flex-grow: 1;
    }

    textarea {
      min-height: 200px;
      background-color: var(--reverse-color-light);
    }
  }

  .field.slate_wysiwyg {
    margin: $spacing-small 0px;

    .ui.grid > .row {
      .column {
        min-height: 200px;

        .slate_wysiwyg_box {
          background-color: var(--reverse-color-light);
        }

        .wrapper {
          flex-grow: 1;
        }
      }
    }
  }

  .field-wrapper-amparo_legal {
    .ReactVirtualized__Grid.ReactVirtualized__List {
      width: 100% !important;

      .ReactVirtualized__Grid__innerScrollContainer {
        max-width: 100% !important;
      }
    }
  }

  .field-wrapper-itens_compra {
    .ui.stackable.equal.width.grid {
      > .row.stretched {
        margin: 0px 15px;
        box-shadow: 10px 5px 5px var(--reverse-color-light);

        > .column.field-column {
          width: 33% !important;
          padding: 10px 10px !important;
          margin: 0em 0em !important;
          box-shadow: none !important;
        }

        > .column.term-actions {
          width: 100% !important;
          padding: 10px 10px !important;
          margin: 0em 0em !important;
          box-shadow: none !important;

          > button {
            width: 10% !important;
            margin-right: 5px;
            margin-left: auto;
          }
        }
      }
    }

    .ui.checkbox {
      > label {
        font-size: 0px;
      }
    }
  }
}
