.block.listing,
.block.listing.has--backgroundColor--grey {
  .items {
    .listing-item {
      .card-container {
        background-color: var(--main-color);

        .content {
          margin-top: 40px;

          div.headline {
            color: var(--reverse-color);
            text-align: center;
          }

          h2 {
            margin-bottom: 5px !important;
            color: var(--reverse-color);
            @include block_grid_h2();
          }

          p {
            color: var(--reverse-color);
            @include block_grid_p();
          }
        }

        &.informacao-financeira {
          .item {
            min-height: 70px;

            .content {
              h2 {
                margin-bottom: 0;
              }

              p {
                display: none;
              }

              &:hover {
                h2 {
                  display: none;
                }

                p {
                  display: block;
                }
              }
            }
          }
        }

        &.comissao {
          min-height: 170px !important;
        }
      }
    }
  }
}

// Simple Grid
#page-add .block.listing.simple-grid,
#page-edit .block.listing.simple-grid,
.block.listing.simple-grid,
.block.listing.simple-grid.has--backgroundColor--grey {
  .items.lateral {
    display: flex;
    @include default-container-width();
    flex-wrap: wrap;

    .listing-item {
      width: 50% !important;
      padding: 0 10px;
      border-bottom: unset;
      margin-bottom: 5px;

      a {
        display: flex;
        width: 100%;
        align-items: flex-start;
      }

      .card-container {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 5px;
        background-color: unset;

        .item-image {
          width: 33% !important;
          text-align: center;

          img {
            width: 100%;
            min-height: 110px;
            margin-right: 0 !important;
            aspect-ratio: 1 / 1;
          }
        }

        .item {
          width: 67% !important;

          .content {
            margin-top: 10px;
            text-align: left;

            h2 {
              margin-bottom: 8px;
              color: var(--main-color-dark);
              @include add(size, m);
              text-align: left;
            }

            p {
              color: var(--alternate-color);
              @include add(size, xs);
              text-align: left;
            }
          }
        }
      }
    }
  }
}

// Profiles
#page-add,
#page-edit,
#page-document {
  .block.listing.profiles,
  .block.listing.profiles.has--backgroundColor--grey {
    .items.profiles {
      display: flex;
      @include default-container-width();
      flex-wrap: wrap;
      @media only screen and (min-width: $tablet-breakpoint) {
        .listing-item {
          width: 25% !important;
        }
      }
      @media only screen and (max-width: $tablet-breakpoint) {
        .listing-item {
          width: 50% !important;
        }
      }
      @media only screen and (max-width: $largest-mobile-screen) {
        .listing-item {
          width: 100% !important;
        }
      }

      .listing-item {
        padding: 0 10px;
        border-bottom: unset;
        margin-bottom: 5px;

        a {
          display: flex;
          width: 100%;
          align-items: flex-start;
        }

        .card-container {
          width: 100%;
          height: 100%;
          min-height: 390px;
          padding: 0;
          background-color: var(--main-color);

          .item-image {
            display: block;
            width: 100% !important;
            padding: 2px;
            background: var(--reverse-color-light);
            text-align: center;

            img {
              width: 100%;
              min-height: 110px;
              margin-right: 0 !important;
            }
          }

          &.profile {
            .item-image img {
              aspect-ratio: 1 / 1;
            }
          }

          .item {
            display: block;
            width: 100% !important;
            min-height: 120px;

            .content {
              padding: 0 10px 5px 10px;
              margin-top: 10px;
              text-align: center;

              .headline {
                @include add(size, xs);
                @include add(weight, semi-bold);
              }

              h2 {
                margin-top: 0px;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }

    .pagination-wrapper {
      display: none;
    }
  }
}

// Four Columns
.block.listing.three-columns,
.block.listing.three-columns.has--backgroundColor--grey {
  .items.three-columns {
    display: flex;
    flex-wrap: wrap;
    @include default-container-width();

    @media only screen and (min-width: $tablet-breakpoint) {
      .listing-item {
        width: 33% !important;
      }
    }
    @media only screen and (max-width: $tablet-breakpoint) {
      .listing-item {
        width: 50% !important;
      }
    }
    @media only screen and (max-width: $largest-mobile-screen) {
      .listing-item {
        width: 100% !important;
      }
    }

    .listing-item {
      padding: 0 10px;
      border-bottom: unset;
      margin-bottom: 5px;

      a {
        display: flex;
        width: 100%;
        align-items: flex-start;

        .card-container {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 10px 2px;
          background-color: var(--main-color);

          .item-image {
            display: block;
            width: 100% !important;
            padding: 2px;
            background: var(--reverse-color-light);
            text-align: center;

            img {
              width: 100%;
              min-height: 110px;
              margin-right: 0 !important;
            }
          }

          &.profile {
            .item-image img {
              aspect-ratio: 1 / 1;
            }
          }

          .item {
            display: block;
            width: 100% !important;
            min-height: 100px;
            padding: 20px 10px;

            .content {
              padding: 0 10px 5px 10px;
              margin-top: 10px;
              text-align: center;

              .headline {
                @include add(size, xs);
                @include add(weight, semi-bold);
              }

              h2 {
                margin-top: 0px;
                margin-bottom: 8px;
                color: var(--reverse-color);
              }

              p {
                color: var(--reverse-color);
              }
            }
          }
        }
      }
    }
  }

  .pagination-wrapper {
    display: none;
  }
}

// InfoLegislativa Grid
body.contenttype-infolegislativa {
  #page-add,
  #page-edit,
  #page-document {
    .block.listing.grid {
      .card-container {
        > img {
          display: none;
        }

        &.comissao {
          .headline {
            border: unset;
          }
        }
      }
    }

    .pagination-wrapper {
      display: none;
    }
  }
}
