.q.container.barra-wrapper {
  max-width: 100% !important;
  background-color: #4e4e4e !important;

  .barra {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: right;
    padding: $spacing-xs 0px;

    .option {
      display: inline flex;
      min-width: 50px;
      align-items: center;
      margin-right: $spacing-small;

      &.contraste {
        min-width: 30px;
      }

      &.fonte {
        min-width: 80px;
      }

      &.link {
        min-width: 160px;
      }

      button {
        border: unset;
        background: unset;
        color: white;
        font-size: 18px;
        font-weight: 600;
        @include add(height, s);

        > svg {
          height: 20px !important;
        }
      }
    }

    .link.texto {
      .label {
        padding-left: $spacing-xs;
        color: white !important;
        font-size: 18px;
        font-weight: 600;
        @include add(height, s);
      }

      svg.icon {
        fill: white !important;
      }
    }
  }
}
