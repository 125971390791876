#page-document .block,
#page-add .block,
#page-edit .block {
  &.vereador {
    margin-top: $spacing-small;
    @include default-container-width();

    .vereadorWrapper {
      .q.container.card {
        display: flex;
        width: 100%;
        justify-content: start;
        box-shadow:
          0 1px 3px 0 var(--reverse-color-light),
          0 0 0 1px var(--reverse-color-light);

        .image {
          display: flex;
          width: 20% !important;
          height: 100% !important;
          background-color: var(--reverse-color-light);

          > img.portrait {
            max-width: 100%;
            max-height: 100%;
            aspect-ratio: 3/4;
            object-fit: cover;
          }
        }

        .content {
          display: block;
          width: 80%;
          align-self: stretch;
          padding-top: $spacing-small;
          border-top: unset;
          margin-left: $spacing-small;

          > .nome {
            @include text-heading-h2();
            @include word-break();

            span {
              font-size: inherit;
              font-weight: inherit;
              line-height: inherit;
            }
          }

          > .meta {
            color: rgba(0, 0, 0, 0.4);
            @include add(size, s);
          }

          > .dados {
            ul {
              padding-inline-start: 0px;

              li {
                &:first-child {
                  padding-top: $spacing-small;
                }
                padding-bottom: $spacing-small;
                list-style-type: none;
              }
            }
          }
        }
      }
    }
  }
}
