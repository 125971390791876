:root {
  --background-color: #ffffff;
  --reverse-color: rgb(236, 240, 241);
  --main-color: rgb(54, 174, 221);
  --main-color-light: rgba(39, 143, 176, 0.526);
  --alternate-color: rgb(39, 143, 176);
  --main-color-dark: #005b9e;
  --reverse-color-light: #ececec;
  --text-color: #333;
  --narrow-container-width: 1024px;
  --default-container-width: 1024px;
  --layout-container-width: 1440px;
  --breadcrumbs-background-color: #8fbcd8;
  --breadcrumbs-text-color: #ffffff;
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-bolder: 900;
  --font-size-2xs: 12px;
  --font-size-xs: 14px;
  --font-size-s: 18px;
  --font-size-m: 21px;
  --font-size-l: 24px;
  --font-size-xl: 30px;
  --font-size-2xl: 33px;
  --font-size-3xl: 36px;
  --font-size-4xl: 42px;
  --font-size-5xl: 48px;
  --line-height-xs: 16px;
  --line-height-s: 18px;
  --line-height-m: 24px;
  --line-height-l: 30px;
  --line-height-xl: 33px;
  --line-height-2xl: 36px;
  --line-height-3xl: 42px;
  --line-height-4xl: 48px;
  --line-height-5xl: 56px;
}

body.acc-font-s {
  --font-size-2xs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 18px;
  --font-size-l: 21px;
  --font-size-xl: 24px;
  --font-size-2xl: 30px;
  --font-size-3xl: 33px;
  --font-size-4xl: 36px;
  --font-size-5xl: 42px;
  --line-height-xs: 14px;
  --line-height-s: 16px;
  --line-height-m: 18px;
  --line-height-l: 24px;
  --line-height-xl: 30px;
  --line-height-2xl: 33px;
  --line-height-3xl: 36px;
  --line-height-4xl: 42px;
  --line-height-5xl: 48px;
}

body.acc-font-m {
  --font-size-2xs: 12px;
  --font-size-xs: 14px;
  --font-size-s: 18px;
  --font-size-m: 21px;
  --font-size-l: 24px;
  --font-size-xl: 30px;
  --font-size-2xl: 33px;
  --font-size-3xl: 36px;
  --font-size-4xl: 42px;
  --font-size-5xl: 48px;
  --line-height-xs: 16px;
  --line-height-s: 18px;
  --line-height-m: 24px;
  --line-height-l: 30px;
  --line-height-xl: 33px;
  --line-height-2xl: 36px;
  --line-height-3xl: 42px;
  --line-height-4xl: 48px;
  --line-height-5xl: 56px;
}

body.acc-font-l {
  --font-size-2xs: 14px;
  --font-size-xs: 18px;
  --font-size-s: 21px;
  --font-size-m: 24px;
  --font-size-l: 30px;
  --font-size-xl: 33px;
  --font-size-2xl: 36px;
  --font-size-3xl: 42px;
  --font-size-4xl: 48px;
  --font-size-5xl: 54px;
  --line-height-xs: 18px;
  --line-height-s: 24px;
  --line-height-m: 30px;
  --line-height-l: 33px;
  --line-height-xl: 36px;
  --line-height-2xl: 42px;
  --line-height-3xl: 48px;
  --line-height-4xl: 56px;
  --line-height-5xl: 64px;
}

body.acc-cont-d {
  --background-color: #ffffff;
  --main-color: rgb(54, 174, 221);
  --main-color-light: rgba(39, 143, 176, 0.526);
  --main-color-dark: #005b9e;
  --reverse-color: rgb(236, 240, 241);
  --reverse-color-light: #ececec;
  --alternate-color: rgb(39, 143, 176);
  --text-color: #333;
}

body.acc-cont-h {
  --background-color: #333;
  --main-color: #005b9e;
  --main-color-light: rgba(39, 143, 176, 0.526);
  --main-color-dark: rgba(39, 143, 176, 0.226);
  --alternate-color: #005b9e;
  --reverse-color: #ececec;
  --reverse-color-light: #ececec;
  --breadcrumbs-background-color: #005b9e;
  --breadcrumbs-text-color: #ffffff;
  --text-color: #ffffff;

  .ui.secondary.pointing.menu {
    .item {
      color: var(--text-color) !important;
    }
  }
}

body {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;

  .ui.segment {
    background-color: var(--background-color) !important;
  }
}

a,
a:-webkit-any-link {
  color: var(--text-color);

  &:hover {
    text-decoration: solid;
    @include add(weight, semi-bold);
  }
}
