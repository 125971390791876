.header-estado {
  display: flex;
  align-items: center;

  .documentFirstHeading {
    align-self: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .state {
    align-self: center;
    padding: 5px 20px;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: auto;
    @include add(size, xs);
    @include add(weight, semi-bold);
  }
}

.state {
  align-self: center;
  padding: 5px 20px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: auto;
  @include add(size, xs);
  @include add(weight, semi-bold);

  &.state-concluida,
  &.state-concluido,
  &.state-compativel,
  &.state-aprovadas,
  &.state-realizado,
  &.state-desclassificada,
  &.state-vigente {
    background-color: green;
    color: var(--reverse-color);
  }

  &.state-aberto,
  &.state-andamento,
  &.state-novo {
    background-color: blue;
    color: var(--reverse-color);
  }

  &.state-criado,
  &.state-encerrado {
    background-color: var(--reverse-color-light);
    color: var(--main-color);
  }

  &.state-cancelado,
  &.state-incompativel,
  &.state-rejeitadas,
  &.state-classificada,
  &.state-apenado,
  &.state-paralizada {
    background-color: red;
    color: var(--reverse-color);
  }

  &.state-ativo {
    background-color: green;
    color: var(--reverse-color);
  }

  &.state-inativo {
    background-color: var(--reverse-color-light);
    color: var(--main-color);
  }
}
