.demonstracao_contabil-view {
  max-width: var(--narrow-container-width);
  margin-right: auto;
  margin-left: auto;

  .tabela {
    &.documentos {
      margin-bottom: 10px;
    }
  }
}
