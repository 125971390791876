.block.__button {
  a {
    &.external::after {
      display: none !important;
    }

    button.ui.button {
      border: 1px solid var(--main-color);
      background-color: var(--main-color);
      color: var(--reverse-color);

      &:hover {
        border: 1px solid var(--alternate-color);
        background-color: var(--alternate-color);
        color: var(--reverse-color);
      }
    }
  }
}
