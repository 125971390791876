#page-document.comissao-view {
  .participante {
    .portraitCell {
      width: 20% !important;

      .portraitWrapper {
        overflow: hidden;
        width: 60px !important;
        height: 80px !important;
        margin: auto;
      }

      img.portrait {
        height: 80px;
        object-fit: cover;
      }
    }

    .info {
      width: 40% !important;
    }
  }

  .download.files {
    text-align: center;

    a {
      &::after {
        content: unset;
      }
    }
  }
}
