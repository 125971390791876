.informacaoceap-view {
  .informacoes {
    padding: 10px;

    .data-metadata {
      @include default-container-width();
      padding: 10px;
      border: 1px solid var(--main-color-dark);
      margin-bottom: 1rem;
      color: var(--main-color-dark);

      .row {
        padding-top: 3px;
        padding-bottom: 3px;

        &:first-child,
        &:last-child {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }

      .metadata-title {
        @include add(weight, bold);
      }

      .metadata-timeframe {
        @include add(weight, medium);
      }

      .metadata-label {
        @include add(weight, medium);
      }

      .metadata-value {
        @include add(weight, light);
      }

      .actions.downloads {
        a.external::after {
          content: '';
        }
      }
    }

    #results {
      max-width: var(--layout-container-width);
      margin-right: auto;
      margin-left: auto;

      .rdt_TableCol_Sortable {
        > div {
          text-align: center;
          white-space: break-spaces;
        }
      }

      .rdt_TableCell {
        padding-right: 7px;
        padding-left: 7px;

        div:first-child {
          white-space: pre-wrap;
        }
      }

      .level {
        &.level-1 .rdt_TableRow {
          background-color: rgba(100, 100, 100, 0.1);
        }

        &.level-2 .rdt_TableRow {
          background-color: rgba(100, 100, 100, 0.2);
        }

        &.level-3 .rdt_TableRow {
          background-color: rgba(100, 100, 100, 0.3);
        }

        &.level-4 .rdt_TableRow {
          background-color: rgba(100, 100, 100, 0.4);
        }

        &.level-5 .rdt_TableRow {
          background-color: rgba(100, 100, 100, 0.5);
        }
      }
    }

    #signature {
      padding-top: 20px;
      text-align: center;

      .text {
        margin-bottom: 0px;
        @include add(size, xs);
        @include add(weight, semi-bold);
      }

      .linkEmprel {
        img {
          width: 100px;
        }
      }
    }
  }
}
