.ui.styled.accordion.contentaccordion {
  border-top: 1px solid $black;

  &:last-child {
    border-bottom: 1px solid $black;
  }

  .title.accordion-title {
    padding: 20px 0 20px 0;
    background-color: unset;

    &.align-arrow-right {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }

    & > svg {
      height: 50px !important;
      fill: $grey !important;
      transform: rotate(-180deg);
    }

    & > span {
      color: var(--text-color);
      text-transform: none;
      @include add(size, l);
      @include add(weight, semi-bold);
    }

    &.active > span {
      color: $black;
    }

    &.active > svg {
      fill: $black !important;
      transform: rotate(180deg);
    }
  }

  .content {
    padding: 0;
  }
}
