.block.contato {
  .contatoWrapper {
    max-width: 700px;
    padding: 15px 10px;
    margin-right: auto;
    margin-left: auto;
    box-shadow:
      0 1px 3px 0 #d4d4d5,
      0 0 0 1px #d4d4d5;

    &:hover {
      transform: translateY(-3px);
    }

    .colunas {
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-top: 3px;

      .coluna {
        width: 50%;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 2px;
      @include add(size, xs);
      @include add(weight, medium);
      line-height: 20px;

      &.setor {
        padding-bottom: 3px;
        border-bottom: rgba(0, 0, 0, 0.4) 1px solid;
        color: rgba(0, 0, 0, 0.4);
        @include add(size, xs);
      }

      &.funcao {
        @include add(size, 2xs);
      }

      &.nome {
        width: 100%;
        padding: 3px 0;
        @include add(size, m);
        @include add(weight, bold);
        line-height: 24px;
      }

      .icon {
        padding-right: 10px;
      }
    }
  }
}
