// Next Previous navigation
.ui.container.nextPrevious {
  display: flex;
  max-width: $default-container-width !important;
  flex-direction: row;
  margin: 0 10px;

  .ui.npItem {
    width: 100%;
    max-width: 200px;
    height: 50px;
    padding: 10px 5px;
    transition: all 0.3s ease;

    &.previousItem {
      margin-right: 20px;
      text-align: left;

      .npIcon {
        float: left;
      }
    }

    &.nextItem {
      margin-left: auto;
      text-align: right;

      .npIcon {
        float: right;
      }
    }
  }
}
