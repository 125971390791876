#main .header-wrapper .header .search-wrapper .search {
  .search-button {
    button {
      svg {
        fill: var(--reverse-color) !important;
      }
    }
  }

  .search-bar {
    background-color: var(--main-color);

    &.active {
      height: 175px;
    }

    .searchbox {
      border-bottom: 2px solid var(--reverse-color);

      input {
        width: 100%;
        padding-left: $spacing-small;
        background-color: var(--main-color-light);
        color: var(--reverse-color);

        &::placeholder {
          color: var(--reverse-color);
        }

        &::selection {
          background-color: var(--reverse-color);
          color: var(--main-color);
        }
      }

      > button svg {
        fill: var(--reverse-color) !important;
      }
    }
  }
}
