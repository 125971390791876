body.acc-cont-h .actions.downloads {
  svg {
    fill: var(--text-color) !important;
  }
}

.actions.downloads {
  margin-left: $spacing-small;

  > a {
    padding: $spacing-xs;

    &.external::after {
      display: none !important;
    }

    svg {
      fill: var(--text-color) !important;
    }
  }
}
