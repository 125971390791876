.block.areas {
  @include default-container-width();

  h2.headline {
    @include default-container-width();
  }

  .ui.cards.subareas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include default-container-width();

    @media only screen and (min-width: $tablet-breakpoint) {
      .card {
        width: 32% !important;
      }
    }
    @media only screen and (max-width: $tablet-breakpoint) {
      .card {
        width: 45% !important;
      }
    }
    @media only screen and (max-width: $largest-mobile-screen) {
      .card {
        width: 100% !important;
      }
    }

    .card {
      display: flex;
      min-height: 200px;
      align-items: center;
      padding: $spacing-small;
      border-radius: 10px;
      border-bottom: unset;
      margin-bottom: 5px;
      background: var(--reverse-color-light);

      > .icon {
        display: inline-block;
        width: 100%;
        min-height: 80px !important;
        text-align: center;
      }

      > .content {
        display: inline-block;
        padding: 0.5em 0.5em;
        text-align: center;
      }
    }
  }
}
