body.acc-cont-h .externalDataForm {
  background-color: var(--background-color) !important;

  h2.headline {
    border-bottom-color: var(--text-color) !important;
    color: var(--text-color) !important;
  }

  .form-group {
    .field {
      > label {
        color: var(--text-color) !important;
      }
    }
  }
}

.externalDataForm {
  background-color: var(--reverse-color-light);

  .formWrapper {
    padding: 30px 0 30px 0;
    @include default-container-width();

    .ui.form.rjsf {
      .actions {
        display: flex;
        max-width: 90%;
        justify-content: center;
      }

      .form-group.field.field-object {
        width: 90%;
        margin-right: auto;
        margin-left: auto;

        .list-inline {
          list-style-type: none;

          .list-inline-item {
            width: 33%;
          }
        }
      }

      .form-group.field.field-string {
        display: block;
        width: 100%;

        &.half {
          display: inline-block;
          width: 50%;
          margin-right: auto;
          margin-left: auto;

          &:nth-of-type(odd) {
            padding: 5px 5px 5px 0;
          }

          &:nth-of-type(even) {
            padding: 5px 1px;
          }
        }

        .ui.fluid.input {
          input {
            padding: 5px 10px;
          }
        }

        textarea {
          padding: 5px 10px;
        }

        .ui.fluid.selection.scrolling.dropdown {
          padding-left: 10px;
        }
      }

      .ui.danger.button {
        margin-left: 150px;
        background-color: var(--alternate-color);
        color: var(--reverse-color);
      }
    }
  }
}
