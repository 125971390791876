.exportRow {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  padding: $spacing-small;
  background-color: var(--main-color);

  .label {
    @include add(size, s);
    @include add(weight, semi-bold);
    color: var(--reverse-color);
  }

  .actions.downloads {
    margin-left: $spacing-small;

    svg {
      fill: var(--reverse-color) !important;
    }
  }
}
