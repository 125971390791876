.contratacoes-items {
  .rdt_TableRow {
    padding: $spacing-small 0px !important;
  }

  .text {
    &.wrap {
      text-wrap: wrap;
    }
  }
}
