body.contenttype-contrato,
body.contenttype-documento {
  .contrato-view,
  .documento-view {
    max-width: var(--default-container-width) !important;
    margin-right: auto;
    margin-left: auto;

    header.head-title {
      padding-top: $spacing-small;
      padding-bottom: 0;
    }

    h1.documentFirstHeading {
      margin-top: $spacing-small;
    }

    .tipo_documento {
      margin-top: -20px;
    }
  }
}
