.fornecedor-view.view-wrapper {
  max-width: var(--default-container-width) !important;
  margin-right: auto;
  margin-left: auto;

  .fornecedorHeader {
    display: flex;
    align-items: flex-start;
    @include default-container-width();

    .fornecedorDados {
      display: block;
      width: 90%;
    }

    .nome {
      display: flex;
      align-items: center;

      .documentFirstHeading {
        align-self: center;
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}
