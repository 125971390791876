.sessao {
  margin: $spacing-medium 0px;
}

.column.pill {
  align-self: center;
  padding: 5px 20px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: auto;
  @include add(size, xs);
  @include add(weight, semi-bold);
}

.chamada {
  .column.pill {
    &.column-state {
      &.value-ausentes {
        background-color: var(--reverse-color-light);
        color: var(--main-color);
      }

      &.value-justificados {
        background-color: var(--main-color);
        color: var(--reverse-color);
      }

      &.value-presentes {
        background-color: green;
        color: var(--reverse-color);
      }
    }
  }
}

.apuracao {
  .column.pill {
    &.column-state {
      &.value-ausente {
        background-color: var(--reverse-color-light);
        color: var(--main-color);
      }

      &.value-abstencao {
        background-color: var(--main-color);
        color: var(--reverse-color);
      }

      &.value-favoravel {
        background-color: green;
        color: var(--reverse-color);
      }

      &.value-contrario {
        background-color: red;
        color: var(--reverse-color);
      }

      &.value-presidencia {
        background-color: blue;
        color: var(--reverse-color);
      }
    }
  }
}

.sessao.votacoes {
  .noresults {
    @include add(size, l);
    @include add(weight, semi-bold);
  }

  td.value.resultado {
    @include add(weight, bold);
  }
}
