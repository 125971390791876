.colaborador-view {
  max-width: var(--narrow-container-width);
  margin-right: auto;
  margin-left: auto;

  .colaboradorHeader {
    display: flex;
    align-items: flex-start;
    @include default-container-width();

    .portrait {
      display: block;
      padding: $spacing-small;
    }

    .colaboradorDados {
      display: block;
      width: 90%;
    }

    .nome {
      display: flex;
      align-items: center;

      .documentFirstHeading {
        align-self: center;
        margin-top: 40px;
        margin-bottom: 20px;
      }

      .state {
        align-self: center;
        margin-top: 40px;
        margin-bottom: 20px;
        margin-left: auto;
        @include add(size, xs);
        @include add(weight, semi-bold);

        &.state-ativo {
          padding: 5px 20px;
          border-radius: 10px;
          background-color: green;
          color: var(--reverse-color);
        }

        &.state-inativo {
          border-radius: 5px;
          background-color: var(--reverse-color-light);
          color: var(--main-color);
        }
      }
    }
  }

  .ui.table.details {
    border: unset;

    td {
      @include add(size, xs);

      &.label {
        @include add(weight, semi-bold);
      }

      &.value {
        @include add(weight, semi-bold);
      }
    }
  }
}
