#page-document .block.teaser,
#page-document .block.gridBlock .block.teaser,
#page-document .block.gridBlock .two .block.teaser,
#page-document .block.gridBlock .three .block.teaser,
#page-document .block.gridBlock .four .block.teaser,
#page-add .block-editor-gridBlock .block.teaser,
#page-edit .block-editor-gridBlock .block.teaser {
  .teaser-item {
    background-color: var(--main-color);

    .image-wrapper {
      margin-top: 0px !important;
      margin-bottom: -10px !important;
      background-color: var(--reverse-color-light);
    }

    .content {
      margin-top: 40px;

      h2 {
        margin-bottom: 5px !important;
        color: var(--reverse-color);
        @include block_grid_h2();
        text-align: center;
      }

      p {
        color: var(--reverse-color);
        @include block_grid_p();
      }
    }
  }
}

.block.gridBlock.has--backgroundColor--grey,
#page-add .block-editor-gridBlock.has--backgroundColor--grey,
#page-edit .block-editor-gridBlock.has--backgroundColor--grey {
  .teaser-item {
    background: var(--main-color);

    .image-wrapper {
      background-color: var(--main-color) !important;
    }
  }
}
