.duodecimo-view {
  max-width: var(--narrow-container-width);
  margin-right: auto;
  margin-left: auto;

  .tabela {
    span.main {
      @include add(size, xs);

      &.value {
        @include add(weight, bold);
      }
    }

    span.detail {
      &.title {
        padding-left: 50px;
      }
    }
  }
}
