body.acc-cont-h .externalDataResults {
  background-color: var(--background-color) !important;

  h2.headline {
    border-bottom-color: var(--text-color) !important;
    color: var(--text-color) !important;
  }

  .form-group {
    .field {
      > label {
        color: var(--text-color) !important;
      }
    }
  }
}

.externalDataResults {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: var(--reverse-color-light);

  .data-metadata {
    @include default-container-width();

    .metadata-details.ui.grid {
      @media only screen and (max-width: $largest-mobile-screen) {
        margin-right: $spacing-small;
        margin-left: $spacing-small;
      }

      .row {
        padding: 5px 0px;

        .column {
          &.metadata-label {
            width: 40%;
            @include add(size, xs);
            @include add(weight, light);
          }

          &.metadata-value {
            width: 60%;
            @include add(size, xs);
            @include add(weight, semi-bold);
          }
        }
      }
    }
  }

  .resultados {
    padding: 20px 0px;
    margin-top: 50px;
    background-color: var(--background-color);
  }

  .resultados-wrapper {
    max-width: var(--layout-container-width);
    margin-right: auto;
    margin-left: auto;
    background-color: var(--background-color);

    .rdt_TableCol_Sortable {
      > div {
        text-align: center;
        white-space: break-spaces;
      }
    }

    .empty {
      padding: 20px 0px;
    }

    .row-details {
      padding: 20px 60px;

      .rdt_Table .rdt_TableHeadRow,
      .rdt_Table .rdt_TableRow {
        background-color: rgba(100, 100, 100, 0.1);
      }
    }

    .rdt_TableCell {
      padding-right: 5px !important;
      padding-left: 5px !important;

      .text {
        &.wrap {
          text-wrap: wrap;
        }

        &.pre {
          padding: 15px 30px;
        }
      }
    }

    .rdt_TableRow {
      &.total.row {
        border-top: 2px solid #000;
        background-color: rgba(100, 100, 100, 0.1);

        ~ .total.row {
          border-top: unset;
        }
      }
    }

    .level {
      &.level-1 .rdt_TableRow {
        background-color: rgba(100, 100, 100, 0.1);
      }

      &.level-2 .rdt_TableRow {
        background-color: rgba(100, 100, 100, 0.2);
      }

      &.level-3 .rdt_TableRow {
        background-color: rgba(100, 100, 100, 0.3);
      }

      &.level-4 .rdt_TableRow {
        background-color: rgba(100, 100, 100, 0.4);
      }

      &.level-5 .rdt_TableRow {
        background-color: rgba(100, 100, 100, 0.5);
      }
    }
  }

  .signature {
    padding-top: 20px;
    @include default-container-width();
    text-align: center;

    .text {
      margin-bottom: 0px;
      @include add(size, xs);
      @include add(weight, semi-bold);
    }

    .linkSignature {
      img {
        width: 100px;
      }
    }
  }
}
