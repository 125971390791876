.block.follow_us {
  .title {
    margin-bottom: 20px;
    color: var(--main-color);
  }

  .social-networks {
    .item {
      a.external {
        svg {
          color: var(--main-color);
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
